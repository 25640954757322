import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../../utils/format-number.js';

/**
 * @typedef {object} TotalPriceAndBalanceProps
 * @property {number} totalPricePerPeriod
 * @property {number|null} customerBalance
 * @property {'month' | 'year'} billingPeriod
 */

/**
 * @param {TotalPriceAndBalanceProps} props
 * @returns {JSX.Element}
 */
export default function TotalPriceAndBalance({
    totalPricePerPeriod,
    customerBalance,
    billingPeriod,
    children
}) {
    return (
        <>
            <div data-testid="total-price-per-period">
                <strong>Total price per {billingPeriod}</strong>
                <strong>{totalPricePerPeriod && formatNumber(totalPricePerPeriod, {symbol: '$'})}</strong>
            </div>

            {children}

            {customerBalance && customerBalance < 0 &&
                <div data-testid="account-credit-balance">
                    <strong>Account credit balance</strong>
                    <strong className="green" data-testid="account-credit-balance-value">{formatNumber(customerBalance, {symbol: '$'})}</strong>
                </div>
            }
        </>
    );
}

TotalPriceAndBalance.propTypes = {
    totalPricePerPeriod: PropTypes.number.isRequired,
    discount: PropTypes.object,
    customerBalance: PropTypes.number,
    billingPeriod: PropTypes.string.isRequired
};
