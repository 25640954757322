import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../shared/ModalWrapper';
import BillingMode from './BillingMode';

/**
 * @typedef {object} BillingModeModalProps
 * @property {object} user
 * @property {string} user.id
 * @property {string} user.stripe_token
 * @property {object} subscription
 * @property {string} subscription.id
 * @property {string} subscription.status
 * @property {object} subscription.meta
 * @property {object} subscription.meta.baseProduct
 * @property {string} subscription.meta.baseProduct.stripe_price_id
 * @property {object[]} subscription.meta.addons
 * @property {string} autoPriceId
 * @property {function} handleCloseModal
 * @property {object[]} exceededLimits
 * @property {boolean} autoBillingEnabled
 * @property {function} setAutoBillingEnabled
 * @property {function} setModalIsOpen
 * @property {boolean} modalIsOpen
 * @property {boolean} willCancelAtPeriodEnd
 */

/**
 * @param {BillingModeModalProps} props
 * @returns {JSX.Element}
 */
export default function BillingModeModal({
    user,
    subscription,
    autoPriceId,
    handleCloseModal,
    exceededLimits,
    autoBillingEnabled,
    setAutoBillingEnabled,
    setModalIsOpen,
    modalIsOpen,
    willCancelAtPeriodEnd,
    isGrace
}) {
    const [modalLoadingState, setModalLoadingState] = useState(false);
    const [billingModeText, setBillingModeText] = useState(autoBillingEnabled ? 'Automatic - sit back and relax' : 'Manual - you\'re in control');

    const handleBillingModeTextUpdate = (text) => {
        setBillingModeText(text);
    };

    return (
        <section className="staticfield">
            <header>
                <div className="staticfield-label">Member limit changes</div>
                <ModalWrapper
                    onCloseModal={() => handleCloseModal({reload: false})}
                    title="Update member limit changes"
                    openModalTrigger={<button className="staticfield-link" data-test-btn="open-billing-mode">Edit</button>}
                    isLoading={modalLoadingState}
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                >
                    <BillingMode
                        onCloseModal={handleCloseModal}
                        user={user}
                        subscription={subscription}
                        autoPriceId={autoPriceId}
                        updateBillingModeText={handleBillingModeTextUpdate}
                        autoBillingAvailable={exceededLimits?.length === 0 ? true : false}
                        setModalLoadingState={setModalLoadingState}
                        autoBillingEnabled={autoBillingEnabled}
                        setAutoBillingEnabled={setAutoBillingEnabled}
                        willCancelAtPeriodEnd={willCancelAtPeriodEnd}
                        isGrace={isGrace}
                    />
                </ModalWrapper>
            </header>
            <div className="staticfield-value">{billingModeText}</div>
        </section>
    );
}

BillingModeModal.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        stripe_token: PropTypes.string
    }).isRequired,
    subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        customer_id: PropTypes.string,
        status: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            baseProduct: PropTypes.shape({
                stripe_price_id: PropTypes.string.isRequired
            }).isRequired,
            addons: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                stripe_price_id: PropTypes.string.isRequired
            }))
        }).isRequired
    }).isRequired,
    autoPriceId: PropTypes.string.isRequired,
    handleCloseModal: PropTypes.func,
    exceededLimits: PropTypes.array,
    autoBillingEnabled: PropTypes.bool,
    setAutoBillingEnabled: PropTypes.func,
    setModalIsOpen: PropTypes.func,
    modalIsOpen: PropTypes.bool,
    willCancelAtPeriodEnd: PropTypes.bool,
    isGrace: PropTypes.bool.isRequired
};
