import React from 'react';
import PropTypes from 'prop-types';
import {fromUnixTime, differenceInDays} from 'date-fns';
import {getCouponDiscount} from '../../../utils/billing-utils';

/**
 * @typedef {object} CouponProps
 * @property {string} id
 * @property {number} percent_off
 * @property {number} amount_off
 * @property {'forever'|'once'|'repeating'} duration
 * @property {number} duration_in_months
 * @property {boolean} valid
 */

/**
 * @typedef {object} DiscountSectionProps
 * @property {object} discount
 * @property {number} discount.end
 * @property {number} discount.start
 * @property {CouponProps} discount.coupon
 */

/**
 * @param {DiscountSectionProps} discount
 * @returns {JSX.Element}
 */
export default function DiscountSection({discount}) {
    if (discount?.coupon) {
        const endDate = discount?.end ? new Date(fromUnixTime(discount.end)) : null;
        const startDate = discount?.start ? new Date(fromUnixTime(discount.start)) : null;
        const today = new Date();
        const daysLeft = endDate ? differenceInDays(endDate, today) : 0;
        const hasStartedYet = startDate ? differenceInDays(startDate, today) >= 0 : true;
        const discountText = `-${getCouponDiscount({coupon: discount.coupon, format: true, calculateTotal: false})}`;

        // Three different types of coupons: forever, once, repeating
        // We need to check if the coupon is still valid and if it has started yet
        // We also need to check if the coupon is still valid if it's a repeating coupon
        const isValidForever = discount?.coupon?.duration === 'forever' && hasStartedYet && discount.coupon.valid;
        const isValidOnce = discount?.coupon?.duration === 'once' && hasStartedYet && discount.coupon.valid;
        const isStillValid = discount?.coupon?.duration === 'repeating' && hasStartedYet && daysLeft > 0 && discount.coupon.valid;

        if (isValidForever || isValidOnce || isStillValid) {
            return (
                <div data-testid="discount-section">
                    <strong>Special offer active</strong>
                    <strong className="green" data-testid="discount-text">{discountText}</strong>
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

DiscountSection.propTypes = {
    discount: PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
        coupon: PropTypes.shape({
            id: PropTypes.string,
            percent_off: PropTypes.number,
            amount_off: PropTypes.number,
            duration: PropTypes.string.isRequired,
            duration_in_months: PropTypes.number,
            valid: PropTypes.bool.isRequired
        })
    })
};
