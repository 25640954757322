import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// utils
import formatPeriod from '../../utils/format-period';
import {getTotalPrice} from '../../utils/billing-utils';
// components
import CancelAtPeriodEndWarning from './PlanDetails/CancelAtPeriodEndWarning';
import TotalPriceAndBalance from './PlanDetails/TotalPriceAndBalance';
import DiscountSection from './PlanDetails/DiscountSection';
import AddOns from './PlanDetails/AddOns';
import StripePortalButton from './PlanDetails/StripePortalButton';
import StaffLimitWarning from './PlanDetails/StaffLimitWarning';
import PendingWarning from './PlanDetails/PendingWarning';

/**
 * @typedef {import('./PlanDetails/PendingWarning').PendingSubscription} PendingSubscription
 * @typedef {import('./PlanDetails/StaffLimitWarning').StaffLimits} StaffLimits
 * @typedef {import('./PlanDetails/CancelAtPeriodEndWarning').WillCancelAtPeriodEnd} WillCancelAtPeriodEnd
 * @typedef {import('./PlanDetails/DiscountSection').DiscountSectionProps} DiscountSectionProps
 * @typedef {import('./PlanDetails/AddOns').AddOnItem} AddOnItem
 */

/**
 * @typedef {object} CurrentSiteLimits
 * @property {StaffLimits} staff
 */

/**
 * @typedef {object} CurrentPrice
 * @property {string} base_product
 * @property {string} billing_period
 * @property {number} cost
 * @property {Array<string>} features
 */

/**
 * @typedef {object} BaseProduct
 * @property {string} stripe_price_id
 * @property {number} quantity
 */

/**
 * @typedef {object} SubscriptionMeta
 * @property {BaseProduct} baseProduct
 * @property {AddOnItem[]} addons
 */

/**
 * @typedef {object} PlanDetailsProps
 * @property {CurrentSiteLimits} currentSiteLimits
 * @property {CurrentPrice} currentPrice
 * @property {SubscriptionMeta} subscriptionMeta
 * @property {boolean} isSubdirectory
 * @property {PendingSubscription} pendingSubscription
 * @property {boolean} isPending
 * @property {string} checkoutRoute
 * @property {boolean} isGrace
 * @property {DiscountSectionProps} discount
 * @property {number} customerBalance
 * @property {string} currentPeriodEnd
 * @property {WillCancelAtPeriodEnd} willCancelAtPeriodEnd
 */

/**
 * @param {PlanDetailsProps} props
 * @returns {JSX.Element}
 */
export default function PlanDetails({
    currentSiteLimits,
    currentPrice,
    subscriptionMeta,
    isSubdirectory,
    pendingSubscription,
    isPending,
    checkoutRoute,
    isGrace,
    discount,
    customerBalance,
    currentPeriodEnd,
    willCancelAtPeriodEnd,
    closeAndReload,
    subscriptionId,
    customerId
}) {
    const showPendingWarning = isPending && pendingSubscription?.product && checkoutRoute;
    const isCustomProduct = currentPrice?.base_product?.toLowerCase()?.indexOf('custom') >= 0;
    const showWarningSection = (showPendingWarning || currentSiteLimits?.staff?.exceeded) && !willCancelAtPeriodEnd;
    const showUpdatePlanButton = !willCancelAtPeriodEnd && !isCustomProduct;
    const showStripePortalButton = isCustomProduct;

    // We don't want the automatic price change add-on to appear in the plan details as such
    const addonItems = useMemo(() => {
        return subscriptionMeta?.addons
            ?.filter(addon => addon?.name?.toLowerCase()?.indexOf('autobilling') < 0)
            ?.map(addon => ({
                name: addon.name,
                cost: addon.cost,
                prices: [{
                    cost: addon.cost,
                    billing_period: currentPrice?.billing_period
                }]
            })) || [];
    }, [subscriptionMeta?.addons, currentPrice?.billing_period]);

    // calculate the total price per period
    // we're using `useMemo` to memoize the result and avoid unnecessary re-renders
    const totalPricePerPeriod = useMemo(() => {
        return getTotalPrice({
            cost: currentPrice?.cost,
            quantity: subscriptionMeta?.baseProduct?.quantity ?? 1,
            period: currentPrice?.billing_period,
            selectedAddons: addonItems
        });
    }, [currentPrice?.cost, currentPrice?.billing_period, addonItems, subscriptionMeta?.baseProduct?.quantity]);

    return (
        <>
            {currentPrice?.base_product &&
                <div className="box-plandetails box-wrap">
                    <div className="box-label">Your plan details</div>
                    <div className="box">
                        <div className="plandetails">
                            <div className="plandetails-info">
                                <div className="plandetails-content">
                                    <h2>{currentPrice.base_product} ({currentPrice?.billing_period && formatPeriod(currentPrice.billing_period)})</h2>
                                    {!willCancelAtPeriodEnd && currentPrice?.features &&
                                        <p>Plan with {currentPrice?.features?.filter(feature => feature.indexOf('Everything') < 0).join(', ').toLowerCase()}</p>
                                    }
                                </div>

                                {showStripePortalButton &&
                                    <StripePortalButton customerId={customerId} />
                                }

                                {showUpdatePlanButton &&
                                    <div>
                                        <Link className="gh-btn gh-btn-green plandetails-cta" to="/plans" disabled={isGrace} data-test-btn="update-plan">Update plan &rarr;</Link>
                                    </div>
                                }

                            </div>

                            {showWarningSection &&
                                <div className="plandetails-warning">
                                    {(showPendingWarning) &&
                                        <PendingWarning
                                            pendingSubscription={pendingSubscription}
                                            checkoutRoute={checkoutRoute}
                                        />
                                    }
                                    <StaffLimitWarning
                                        staffLimits={currentSiteLimits?.staff}
                                    />
                                </div>
                            }

                            <CancelAtPeriodEndWarning
                                willCancelAtPeriodEnd={willCancelAtPeriodEnd}
                                currentPeriodEnd={currentPeriodEnd}
                                closeAndReload={closeAndReload}
                                subscriptionId={subscriptionId}
                            />

                            {!showStripePortalButton &&
                                <>
                                    <AddOns
                                        currentPrice={currentPrice}
                                        addonItems={addonItems}
                                        isSubdirectory={isSubdirectory}
                                        baseProductQuantity={subscriptionMeta?.baseProduct?.quantity}
                                    />
                                    <footer>
                                        <TotalPriceAndBalance
                                            totalPricePerPeriod={totalPricePerPeriod}
                                            discount={discount}
                                            customerBalance={customerBalance}
                                            billingPeriod={currentPrice?.billing_period}
                                        >
                                            <DiscountSection discount={discount} />
                                        </TotalPriceAndBalance>
                                    </footer>
                                </>
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

PlanDetails.propTypes = {
    currentSiteLimits: PropTypes.shape({
        staff: PropTypes.shape({
            limit: PropTypes.number,
            total: PropTypes.number,
            exceeded: PropTypes.bool
        })
    }),
    currentPrice: PropTypes.shape({
        base_product: PropTypes.string,
        billing_period: PropTypes.string,
        cost: PropTypes.number,
        features: PropTypes.array
    }),
    subscriptionMeta: PropTypes.shape({
        baseProduct: PropTypes.shape({
            stripe_price_id: PropTypes.string,
            quantity: PropTypes.number
        }),
        addons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            cost: PropTypes.number,
            prices: PropTypes.arrayOf(PropTypes.shape({
                cost: PropTypes.number,
                billing_period: PropTypes.string
            }))
        }))
    }),
    isSubdirectory: PropTypes.bool,
    pendingSubscription: PropTypes.shape({
        product: PropTypes.string,
        period: PropTypes.string
    }),
    isPending: PropTypes.bool,
    checkoutRoute: PropTypes.string,
    isGrace: PropTypes.bool,
    discount: PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
        coupon: PropTypes.shape({
            id: PropTypes.string,
            percent_off: PropTypes.number,
            amount_off: PropTypes.number,
            duration: PropTypes.string,
            duration_in_months: PropTypes.number
        })
    }),
    customerBalance: PropTypes.number,
    currentPeriodEnd: PropTypes.string,
    willCancelAtPeriodEnd: PropTypes.bool,
    closeAndReload: PropTypes.func,
    subscriptionId: PropTypes.string,
    customerId: PropTypes.string
};
