import React from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {object} StaffLimits
 * @property {number} limit
 * @property {number} total
 * @property {boolean} exceeded
 */

/**
 * @typedef {object} StaffLimitWarningProps
 * @property {StaffLimits} staffLimits
 */

/**
 * @param {StaffLimitWarningProps} props
 * @returns {JSX.Element}
 */
export default function StaffLimitWarning({
    staffLimits
}) {
    if (!staffLimits || Object.keys(staffLimits).length === 0 || !staffLimits.exceeded) {
        return null;
    }

    return (
        <p className="red" data-testid="staff-limit-warning">Your plan includes {staffLimits.limit} staff users. You're currently using: {staffLimits.total}. <a href="https://ghost.org/docs/staff/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">Plan limit details</a></p>
    );
}

StaffLimitWarning.propTypes = {
    staffLimits: PropTypes.shape({
        limit: PropTypes.number,
        total: PropTypes.number,
        exceeded: PropTypes.bool
    }).isRequired
};
