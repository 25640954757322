import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {getStripePortalUrl, getPartialCachedBillingData} from '../../../data/api';
import SpinnerButton from '../../shared/SpinnerButton';

/**
 * @param {{customerId: string}} props
 * @returns {JSX.Element}
 */
export default function StripePortalButton({
    customerId
}) {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const currentSite = await getPartialCachedBillingData('currentSite');
        const redirectUrl = currentSite?.url ? `${currentSite.url}/ghost/#/pro/` : null;

        // Open a new window immediately upon user interaction
        const newWindow = window.open('', '_blank');

        const res = await getStripePortalUrl({customerId, redirectUrl});

        setLoading(false);

        if (res?.errors) {
            const loggingError = new Error('Failed to receive Stripe portal URL');

            Sentry.captureException(loggingError, {
                tags: {
                    source: 'stripe-portal-button',
                    shown_to_user: true
                },
                contexts: {
                    full_error: res?.errors?.originalError || res?.errors
                },
                user: {
                    username: customerId
                }
            });

            newWindow.location.href = 'https://billing.stripe.com/p/login/aEU9COeOC1Ej51K5kk';
        }

        if (res.url) {
            newWindow.location.href = res.url;
        }
    };

    return (
        <div className="stripe-portal-button">
            <SpinnerButton
                className={`gh-btn gh-btn-block ${loading ? 'spinner' : ''}`}
                html={
                    loading
                        ? '<span><div class="gh-spinner"></div></span>'
                        : 'View &rarr;'
                }
                onClick={loading ? null : handleSubmit}
                disabled={loading}
            />
        </div>
    );
}

StripePortalButton.propTypes = {
    customerId: PropTypes.string.isRequired
};
