import {Link} from 'react-router-dom';

import successIcon from '../../icons/success.svg';
import failedIcon from '../../icons/failed.svg';
import pendingIcon from '../../icons/pending.svg';

export default function DomainStatusField({status, domain, type, linkTo, params = []}) {
    let icon = successIcon;
    let iconClass = 'domain-status-icon';

    switch (status) {
    case 'ACTIVE':
        iconClass += ' active';
        icon = (<img src={successIcon} className={iconClass} alt="success" />);
        break;
    case 'VERIFIED':
        iconClass += ' active';
        icon = (<img src={successIcon} className={iconClass} alt="success" />);
        break;
    case 'NONE':
        if (type === 'custom-domain') {
            iconClass += ' pending';
            icon = (<img src={pendingIcon} className={iconClass} alt="pending" />);
        }
        break;
    case 'PENDING':
        iconClass += ' pending';
        icon = (<img src={pendingIcon} className={iconClass} alt="pending" />);
        break;
    case 'FAILED':
        iconClass += ' failed';
        icon = (<img src={failedIcon} className={iconClass} alt="failed" />);
        break;
    case 'INVALID':
        iconClass += ' failed';
        icon = (<img src={failedIcon} className={iconClass} alt="failed" />);
        break;
    case 'PROXY':
        iconClass += ' active';
        icon = (<img src={successIcon} className={iconClass} alt="success" />);
        break;
    default:
        break;
    }
    return (
        <div className="domain-status-box">
            <div className="domain-status-content">
                <div className="domain-status-label">
                    {icon}
                    <div>{status === 'PENDING' ? 'Activation in progress' : domain}</div>
                </div>
                {linkTo ?
                    <Link className="staticfield-link" data-test-btn={`edit-${type}`} to={{
                        pathname: linkTo,
                        state: params
                    }}>{status === 'INVALID' && type === 'custom-sending-domain' ? 'Review' : 'Edit'}</Link>
                    : <strong>Active</strong>
                }
            </div>
        </div>
    );
}
