import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import ModalWrapper from './ModalWrapper';

import CancelImmediately from './CancelImmediately';
import CancelAtPeriodEnd from './CancelAtPeriodEnd';

/**
 * Returns the title for the cancel modal
 * @param {cancelImmediately?: boolean} cancelImmediately
 * @returns {string}
 */
const getCancelModalTitle = (cancelImmediately) => {
    if (cancelImmediately) {
        return 'Cancel & delete account';
    }

    return 'Cancel subscription';
};

export default function CancelModal({
    onCloseModal,
    setLoadingState,
    subscription,
    modalIsOpen,
    setModalIsOpen,
    billingData
}) {
    const _verbose = (process?.env?.REACT_APP_VERBOSE && process?.env?.REACT_APP_VERBOSE === 'true') || false;
    const cancelAtPeriodFlowEnabled = (process?.env?.REACT_APP_CANCEL_AT_PERIOD_END && process?.env?.REACT_APP_CANCEL_AT_PERIOD_END === 'true') || false;

    // If the account is in trial, or the flag is set to cancel immediately, or the account is in grace period, we use the cancel immediately flow
    const cancelImmediately = (billingData?.isTrial || !cancelAtPeriodFlowEnabled || billingData?.isGrace) ? true : false;

    if (_verbose) {
        if (billingData?.isTrial) {
            console.log(`[BMA] Trial account, using "cancel now" flow`);
        } else if (billingData?.isGrace) {
            console.log(`[BMA] Grace period account, using "cancel now" flow`);
        } else {
            console.log(`[BMA] Cancellation flow using ${cancelAtPeriodFlowEnabled ? '"cancel at period end"' : '"cancel now"'}`);
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        Sentry.setTag('source', 'cancel-modal');

        Sentry.setExtras({
            siteId: billingData?.currentSite?.id,
            cancelMode: cancelImmediately ? 'immediately' : 'at period end'
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const modalTitle = getCancelModalTitle(cancelImmediately);

    return (
        <ModalWrapper
            onCloseModal={() => onCloseModal({reload: false})}
            title="Cancel account"
            openModalTrigger={<button className="red" data-test-btn="open-cancel">Cancel account</button>}
            isLoading={isLoading}
            setModalIsOpen={setModalIsOpen}
            modalIsOpen={modalIsOpen}
        >
            <div>
                <form className="gh-form cancel-account">
                    <header>
                        <h1>{modalTitle}</h1>
                    </header>

                    {cancelImmediately ?
                        <CancelImmediately
                            isLoading={isLoading}
                            onCloseModal={onCloseModal}
                            setLoadingState={setLoadingState}
                            setErrors={setErrors}
                            setIsLoading={setIsLoading}
                            subscriptionId={subscription?.id}
                            billingData={billingData}
                        />
                        :
                        <CancelAtPeriodEnd
                            isLoading={isLoading}
                            onCloseModal={onCloseModal}
                            setLoadingState={setLoadingState}
                            setErrors={setErrors}
                            setIsLoading={setIsLoading}
                            subscription={subscription}
                            currentPeriodEnd={billingData?.subscription?.current_period_end}
                            hasAutoBillingEnabled={billingData?.hasAutoBillingEnabled}
                            autoPriceId={billingData?.autoPriceId}
                            user={billingData?.user}
                        />
                    }

                    {errors &&
                        <div className="form-error">
                            <p className="error-msg">{errors}</p>
                        </div>
                    }
                </form>
            </div>
        </ModalWrapper>
    );
}

CancelModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    setLoadingState: PropTypes.func.isRequired,
    subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        customer_id: PropTypes.string,
        status: PropTypes.string.isRequired,
        currentPeriodEnd: PropTypes.string
    }).isRequired,
    modalIsOpen: PropTypes.bool,
    setModalIsOpen: PropTypes.func
};

