import React, {useState, useEffect} from 'react';
import {BadRequestError} from '@tryghost/errors';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {
    Elements,
    useStripe,
    useElements,
    CardElement
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
    updateCreditCard,
    setupIntent
} from '../../data/api';

import SpinnerButton from '../shared/SpinnerButton';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_PK);

const CARD_ELEMENT_FONTS = [
    {
        family: 'Inter',
        weight: 400,
        src: 'local("Inter var"), local("Inter"), url(/assets/fonts/Inter-roman.var.woff2) format("woff2")'
    }
];

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#15171A',
            fontWeight: '400',
            fontFamily: 'Inter, sans-serif',
            fontSize: '15px',
            fontSmoothing: 'antialiased',
            textShadow: '#000000',
            '::placeholder': {
                color: '#a9b6bc'
            },
            ':focus::placeholder': {
                color: '#a9b6bc'
            },
            ':-webkit-autofill': {
                color: '#687e87',
                fontWeight: '400',
                fontFamily: 'Inter, sans-serif',
                fontSize: '15px',
                fontSmoothing: 'antialiased',
                textShadow: '#000000'
            }
        },
        invalid: {
            color: '#687e87'
        }
    },
    classes: {
        invalid: 'error',
        complete: 'complete',
        empty: 'empty',
        focus: 'focus',
        webkitAutofill: 'autofill'
    }
};

function CardSection({disabled, isGrace}) {
    return (
        <>
            <header className="billing-contact-header">
                <h1>Update your card details.</h1>
                {isGrace && <p>Update your card details to pay the outstanding balance on your account.</p>}
            </header>
            <div className="gh-input-group">
                <label htmlFor="signup-name">Full name</label>
                <input
                    id="signup-name"
                    className="gh-input"
                    type="text"
                    required
                    name="name"
                    placeholder="Jamie Larson"
                    autoCorrect="off"
                    autoFocus
                    disabled={disabled}
                />
            </div>
            <div className="gh-input-group">
                <label htmlFor="signup-form-cc">Credit or debit card</label>
                <CardElement className="gh-input" options={CARD_ELEMENT_OPTIONS} />
            </div>
        </>
    );
}

CardSection.propTypes = {
    disabled: PropTypes.bool
};

function CardSetupForm(props) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block',
            html: '&#10004; Update complete!',
            type: 'submit',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            type: 'submit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Update',
            type: 'submit',
            disabled: false
        }
    };

    const stripe = useStripe();
    const elements = useElements();

    const [errors, setErrors] = useState(null);
    const [buttonState, setButtonState] = useState({...buttonStates.default});

    const onSetErrors = message => setErrors(message);

    useEffect(() => {
        Sentry.setTags({
            source: 'stripe-update-card',
            stripe: true
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setButtonState({...buttonStates.pending});
        props?.setModalLoadingState(true);
        onSetErrors(null);

        if (!stripe || !elements) {
            props?.setModalLoadingState(false);
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return setButtonState({...buttonStates.default, disabled: true});
        }

        // We first need to create a setup intent to receive the client secret
        const {clientSecret, setupIntentId, errors: stripeErrors} = await setupIntent(props.customerId);

        if (!stripeErrors && clientSecret) {
            // We then need to confirm the card setup with Stripe.
            // When the card requires 2FA/SCA, the user will be prompted to confirm the payment.
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });

            if (result?.error) {
                const stripeError = result.error;
                props?.setModalLoadingState(false);

                const sentryEventObject = {
                    tags: {
                        source: 'stripe-update-card',
                        stripe: true
                    },
                    contexts: {
                        api_response: result,
                        full_error: stripeError
                    },
                    extra: {
                        setupIntentId
                    }
                };

                // Stripe can return four types of errors:
                // 1. card_error
                // 2. idempotency_error
                // 3. api_error
                // 4. invalid_request_error
                //
                // We only want to capture card_error as an info level message
                // and the rest as error level messages.
                // `card_error` is returned when the payment fails, e.g. insufficient funds.
                // `validation_error` is returned when the card is invalid, e.g. CVC is incorrect.
                // `setup_intent_authentication_failure` is returned when the 2FA/SCA confirmation fails.
                if (
                    stripeError?.type === 'card_error'
                        || stripeError?.type === 'validation_error'
                        || stripeError?.code === 'setup_intent_authentication_failure'
                ) {
                    Sentry.withScope((scope) => {
                        scope.setTags(sentryEventObject.tags);
                        scope.setContext(sentryEventObject.context);
                        scope.setExtra(sentryEventObject.extra);
                        Sentry.captureMessage(stripeError?.message || 'Error confirming card setup in Stripe.');
                    });
                } else {
                    // All other errors are captured as exceptions
                    // as they are not related to the card itself.
                    const loggingError = new BadRequestError({...stripeError});
                    Sentry.captureException(loggingError, sentryEventObject);
                }

                onSetErrors(result?.error?.message || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
                return setButtonState({...buttonStates.fail});
            } else {
                const requestData = {userId: props.userId, customerId: props.customerId, setupIntentId};
                // With the given setup intent, we trigger the update in Daisy.
                // For a user in dunning (grace) period, we'll also try to pay the outstanding balance.
                const res = await updateCreditCard(requestData);

                if (res?.errors) {
                    props?.setModalLoadingState(false);

                    const sentryEventObject = {
                        tags: {
                            source: 'stripe-update-card',
                            stripe: false
                        },
                        contexts: {
                            api_response: res,
                            full_error: res?.errors?.originalError || res?.errors,
                            request_data: requestData
                        },
                        extra: {
                            setupIntentId
                        }
                    };

                    // The error code is provided by Daisy when the attempt to process the payment during
                    // a card update fails.
                    if (res.errors?.originalError?.code === 'STRIPE_PAYMENT_FAILED') {
                        // If the payment failed for the outstanding balance, we want to capture this as an info level message
                        // as it's not an error in the code, but rather expected behaviour.
                        Sentry.withScope((scope) => {
                            scope.setTags(sentryEventObject.tags);
                            scope.setContext(sentryEventObject.context);
                            scope.setExtra(sentryEventObject.extra);
                            Sentry.captureMessage(res.errors?.originalError?.message || 'Error updating credit card in API due to payment failure.');
                        });
                    } else {
                        // If the error is not related to the payment, we want to capture it as an exception.
                        // Convert JSON to Error object, so we can capture the original error fully in Sentry.
                        const loggingError = new Error(res.errors?.originalError?.message || res.errors?.message || 'Error updating credit card in API');
                        loggingError.originalError = res.errors?.originalError || res.errors;
                        Sentry.captureException(loggingError, sentryEventObject);
                    }

                    onSetErrors((props?.isGrace && res?.errors?.message) || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
                    return setButtonState({...buttonStates.fail});
                }

                // SUCCESS!
                props?.setModalLoadingState(false);
                setButtonState({...buttonStates.success});
                return props.onCloseModal({reload: false, delay: 1000});
            }
        } else {
            // If we get here, something went wrong with the setup intent and Stripe returned an error.
            props?.setModalLoadingState(false);

            const loggingError = new Error(stripeErrors?.message || 'Error creating setup intent in Stripe.');
            loggingError.originalError = stripeErrors;

            Sentry.captureException(loggingError, {
                tags: {
                    source: 'stripe-update-card',
                    stripe: true
                },
                extra: {
                    setupIntentId
                },
                contexts: {
                    full_error: stripeErrors
                },
                user: {
                    id: props.userId,
                    username: props.customerId
                }
            });

            onSetErrors(stripeErrors?.message || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
            return setButtonState({...buttonStates.fail});
        }
    };

    return (
        <form className="gh-form" onSubmit={handleSubmit} data-testid="stripe-update-card">
            <CardSection disabled={buttonState.disabled} isGrace={props?.isGrace} />
            <SpinnerButton {...buttonState} data-test-btn="update-card" />
            {errors &&
                <div className="form-error">
                    <p className="error-msg">{errors}</p>
                </div>
            }
        </form>

    );
}

CardSetupForm.propTypes = {
    customerId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    setModalLoadingState: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired,
    isGrace: PropTypes.bool
};

export default function StripeUpdateCard(props) {
    return (
        <Elements stripe={stripePromise} fonts={CARD_ELEMENT_FONTS}>
            <CardSetupForm {...props} />
        </Elements>
    );
}

StripeUpdateCard.propTypes = {
    customerId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    setModalLoadingState: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired,
    isGrace: PropTypes.bool
};
