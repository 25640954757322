import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {updateBillingContact, getPartialBillingData} from '../../data/api';
import SpinnerButton from '../shared/SpinnerButton';

export default function BillingContact({
    user,
    onCloseModal,
    setModalLoadingState
}) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block',
            html: '&#10004; Update complete!',
            type: 'submit',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            type: 'submit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Update',
            type: 'submit',
            disabled: false
        }
    };

    const [billingContact, setBillingContact] = useState(user?.billing_contact || '');
    const [errors, setErrors] = useState(null);
    const [buttonState, setButtonState] = useState({...buttonStates.default});

    const isValidEmail = (email) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    };

    const sanitizeInput = (event) => {
        event.preventDefault();

        const cleanBillingContact = billingContact?.length ? billingContact.trim() : '';

        if (cleanBillingContact && !isValidEmail(cleanBillingContact)) {
            setErrors('Enter a valid email address');
            return;
        }

        setBillingContact(cleanBillingContact);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (billingContact && !isValidEmail(billingContact)) {
            setErrors('Enter a valid email address');
            return;
        }

        if (!user || !user?.id) {
            setErrors('Unable to update billing contact. Refresh the page and try again.');
            return;
        }

        setModalLoadingState(true);
        setButtonState({...buttonStates.pending});
        setErrors(null);

        const res = await updateBillingContact({
            userId: user.id,
            billingContact: billingContact?.length ? billingContact.trim() : ''
        });

        if (res?.errors) {
            const message = res?.errors?.message ?? 'Unable to update billing contact. Refresh the page and try again or contact support@ghost.org';

            Sentry.captureException(res?.errors, {
                tags: {
                    source: 'billing-contact',
                    shown_to_user: true
                },
                user: {
                    id: user.id,
                    email: user.email_address,
                    username: user?.stripe_token_sg
                }
            });

            setErrors(message);
            setModalLoadingState(false);
            return setButtonState({...buttonStates.fail});
        }

        await getPartialBillingData('users');

        // SUCCESS!
        setModalLoadingState(false);
        setButtonState({...buttonStates.success});
        return onCloseModal({reload: false, delay: 1000});
    };

    const handleInputChange = (event) => {
        setBillingContact(event.target.value);
        setErrors(null);
        setButtonState({...buttonStates.default});
    };

    return (
        <form className="gh-form" onSubmit={handleSubmit}>
            <header className="billing-contact-header">
                <h1>Billing contact</h1>
                <p>This email address will be used to deliver invoices,  receipts, and notifications about any payment problems.</p>
            </header>

            <div className="gh-input-group">
                <label htmlFor="billing-contact">Billing contact</label>
                <input
                    id="billing-contact"
                    className="gh-input"
                    name="name"
                    placeholder={user?.email_address || 'billing@example.com'}
                    autoCorrect="off"
                    data-testid="input-billing-contact"
                    autoFocus
                    value={billingContact}
                    onChange={event => handleInputChange(event)}
                    onBlur={(event => sanitizeInput(event))}
                />
            </div>

            <SpinnerButton {...buttonState} data-test-btn="billing-contact" />

            {errors &&
                <div className="form-error">
                    <p className="error-msg">{errors}</p>
                </div>
            }
        </form>
    );
}

BillingContact.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email_address: PropTypes.string.isRequired,
        billing_contact: PropTypes.string
    }).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    setModalLoadingState: PropTypes.func.isRequired
};
