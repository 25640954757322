import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../../utils/format-number';

/**
 * @typedef {object} AddOnItem
 * @property {string} name
 * @property {number} cost
 * @property {{name: string, cost: number}[]} prices
 */

/**
 * @typedef {object} CurrentPrice
 * @property {string} base_product
 * @property {number} cost
 */

/**
 * @typedef {object} AddOnsProps
 * @property {CurrentPrice} currentPrice
 * @property {AddOnItem[]} addonItems
 * @property {boolean} isSubdirectory
 * @property {number} baseProductQuantity
 */

/**
 * @param {AddOnItem} addon
 * @returns {JSX.Element}
 */
function AddOnItem({
    addon
}) {
    const [addonItem, setAddonItem] = useState({
        name: addon?.prices?.[0]?.name || addon?.name,
        cost: addon?.prices?.[0]?.cost || addon?.cost
    });

    useEffect(() => {
        // Subdirectory needs to be renamed for the UI
        if (addonItem?.name?.toLowerCase()?.indexOf('subdirectory') >= 0) {
            setAddonItem({
                name: 'Subdirectory install',
                cost: addonItem.cost
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="invoices-item">
            <div className="invoice-desc">{addonItem.name}</div>
            <div className="invoice-price">{formatNumber(addonItem.cost, {symbol: '$'})}</div>
        </div>
    );
}

AddOnItem.propTypes = {
    addon: PropTypes.shape({
        name: PropTypes.string,
        cost: PropTypes.number,
        prices: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            cost: PropTypes.number
        }))
    }).isRequired
};

/**
 * @param {AddOnsProps} props
 * @returns {JSX.Element}
 */
export default function AddOns({
    currentPrice,
    addonItems,
    isSubdirectory,
    baseProductQuantity = 1
}) {
    return (
        <>
            {addonItems.length ?
                !isSubdirectory ?
                    <div className="plandetails-breakdown">
                        <div className="staticfield-label">Price breakdown</div>
                        <div className="invoices-item">
                            <div className="invoice-desc">{currentPrice.base_product} plan</div>
                            <div className="invoice-price">{formatNumber(currentPrice.cost * baseProductQuantity, {symbol: '$'})}</div>
                        </div>

                        {addonItems.map(({...addon}, i) => (
                            <AddOnItem
                                key={i}
                                addon={addon}
                            />
                        ))}
                    </div>
                    :
                    <div className="plandetails-breakdown">
                        <div className="staticfield-label">Price breakdown</div>
                        <div className="invoices-item">
                            <div className="invoice-desc">{currentPrice.base_product} plan</div>
                            <div className="invoice-price">{formatNumber(currentPrice.cost * baseProductQuantity, {symbol: '$'})}</div>
                        </div>
                        <div className="invoices-item">
                            <div className="invoice-desc">Subdirectory install (incl. in plan)</div>
                        </div>
                    </div>
                : null
            }
        </>
    );
}

AddOns.propTypes = {
    currentPrice: PropTypes.shape({
        base_product: PropTypes.string,
        cost: PropTypes.number
    }).isRequired,
    addonItems: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        cost: PropTypes.number,
        prices: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            cost: PropTypes.number
        }))
    })),
    isSubdirectory: PropTypes.bool,
    baseProductQuantity: PropTypes.number
};
