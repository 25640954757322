import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
    getCachedBillingData,
    getBillingData,
    getForceUpgrade,
    getCustomerBalance
} from '../data/api';
import * as Sentry from '@sentry/react';
import PlanDetails from './Dashboard/PlanDetails';
import Invoices from './Dashboard/Invoices';
import BillingModeModal from './Dashboard/BillingModeModal';
import CancelModal from './shared/CancelModal';
import InvoiceDetailsModal from './shared/InvoiceDetailsModal';
import UpdateCardModal from './stripe/UpdateCardModal';
import BillingContactModal from './Dashboard/BillingContactModal';

let _isMounted = false;

function Dashboard({
    history,
    updateTitle,
    showNav
}) {
    const _verbose = (process?.env?.REACT_APP_VERBOSE && process?.env?.REACT_APP_VERBOSE === 'true') || false;

    const sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    };

    // Trigger the update card details modal for a user in a dunning state
    // The modal can be closed without update, but routes will redirect back to
    // the Dashboard and show it again, until the user left the dunning state.
    const [showCardUpdateModal, setShowCardUpdateModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [billingData, setBillingData] = useState(null);
    const [noCurrentPrice, setNoCurrentPrice] = useState(false);
    const [canHaveAutoBilling, setCanHaveAutoBilling] = useState(false);
    const [autoBillingEnabled, setAutoBillingEnabled] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [updateCardModalIsOpen, setUpdateCardModalIsOpen] = useState(false);
    const [billingModeModalIsOpen, setBillingModeModalIsOpen] = useState(false);
    const [invoiceDetailsModalIsOpen, setInvoiceDetailsModalIsOpen] = useState(false);
    const [billingContactModalIsOpen, setBillingContactModalIsOpen] = useState(false);
    const [customerBalance, setCustomerBalance] = useState(null);

    useEffect(() => {
        showNav(false);

        const fetchCachedBillingData = async () => {
            const cachedBillingData = await getCachedBillingData();

            Sentry.setTags({
                source: 'dashboard'
            });

            setBillingData(cachedBillingData);

            // Handle different states of errors or no permissions:
            //  - Legacy plans or multiple sites per user will resolve in a 403 status
            //  - Lapsed trial or subscriptions have a `forceUpgrade` state. If the current
            //    user is **not** the owner of the site, we let them know to inform the owner.
            //  - Any other error tells the user to contact support.
            if (!cachedBillingData || cachedBillingData?.errors || !cachedBillingData?.availableProducts) {
                setShowError(true);

                if (cachedBillingData?.errors === 403) {
                    setErrorMessage(<>You’re on a special legacy plan. If you need to make any billing or domain changes, reach out to us on <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a></>);
                } else if (cachedBillingData?.errors === 408) {
                    setErrorMessage(<>The request timed out. Try again or contact <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a>.</>);
                } else {
                    setErrorMessage(<>Contact <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a> for changes to your domain or billing.</>);
                }
            }

            // Site with a lapsed trial or subscription are in a `forceUpgrade` state.
            // If the current user is the owner of the site, redirect them to the
            // plan selection to checkout a new subscription.
            if (cachedBillingData?.forceUpgradeOwner || cachedBillingData?.forceUpgradeNoOwner) {
                if (cachedBillingData?.subscription?.status === 'active') {
                    if (_verbose) {
                        console.log('[BMA] Site in forceUpgrade state but with active subscription');
                    }
                    // we do have a subscription, we might not be in a forceUpgrade
                    // state any more
                    const {forceUpgradeOwner, forceUpgradeNoOwner} = await getForceUpgrade();
                    if (_verbose) {
                        console.log('[BMA] Updated forceUpgrade info:', forceUpgradeOwner);
                    }
                    setBillingData({...cachedBillingData, forceUpgradeOwner, forceUpgradeNoOwner});

                    if (forceUpgradeNoOwner) {
                        const reachOutTo = cachedBillingData?.siteOwner?.name ? `${cachedBillingData?.siteOwner?.name} | ${cachedBillingData?.siteOwner?.email}` : 'the owner of the publication';
                        setShowError(true);
                        setErrorMessage(<>Your trial is over, Ask {reachOutTo} to choose a plan.</>);
                    }
                } else {
                    // no active subscription, current forceUpgrade state still applies
                    return history.push('/plans');
                }
            } else if (cachedBillingData?.isIncomplete || cachedBillingData?.isAutoBillingGrace) {
                // Redirect back to Checkout with the current plan to finish the payment
                return history.push(cachedBillingData.checkoutRoute);
            }

            const hasAutoBillingEnabled = cachedBillingData.hasAutoBillingEnabled;
            const autoBillingAddonAvailable = cachedBillingData?.autoPriceId;

            const autoBillingAllowed = Boolean(autoBillingAddonAvailable
                && !!cachedBillingData?.autoPriceId
                && !cachedBillingData?.isForceUpgrade
                && (hasAutoBillingEnabled || cachedBillingData?.user?.show_auto_billing));

            if (cachedBillingData?.isTrial && !cachedBillingData?.errors) {
                return history.push('/plans');
            }

            const stripeCustomerId = cachedBillingData?.subscription?.customer_id || cachedBillingData?.user?.stripe_token;

            if (stripeCustomerId) {
                const customerBalanceResponse = await getCustomerBalance({customerId: stripeCustomerId});

                if (_verbose) {
                    console.log('[BMA] Customer balance:', customerBalanceResponse?.balance, 'Errors:', customerBalanceResponse?.errors);
                }

                if (customerBalanceResponse?.balance < 0 && !customerBalanceResponse?.errors) {
                    setCustomerBalance(customerBalanceResponse?.balance);
                }
            }

            setLoading(false);

            setNoCurrentPrice(!cachedBillingData?.currentPrice?.stripe_price_id);
            setShowCardUpdateModal(
                (cachedBillingData?.isGrace && !cachedBillingData?.isAutoBillingGrace)
                || false
            );
            setAutoBillingEnabled(hasAutoBillingEnabled);
            setCanHaveAutoBilling(autoBillingAllowed);
            setInvoiceDetails(cachedBillingData?.invoiceDetails);
        };

        if (!_isMounted) {
            _isMounted = true;

            updateTitle('Billing and domain settings');
            fetchCachedBillingData();
        }
        return () => {
            _isMounted = false;

            if (billingData?.isGrace && !billingData?.isAutoBillingGrace && !showCardUpdateModal) {
                setShowCardUpdateModal(true);
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof showNav === 'function') {
            if (billingData?.errors || billingData?.isInactive || noCurrentPrice) {
                showNav(false);
            } else {
                showNav(true);
            }
        }
    }, [showNav, billingData, noCurrentPrice]);

    useEffect(() => {
        // Update the auto billing state if it changed
        if (billingData?.hasAutoBillingEnabled !== autoBillingEnabled) {
            setAutoBillingEnabled(billingData?.hasAutoBillingEnabled);
        }
    }, [billingData?.hasAutoBillingEnabled, autoBillingEnabled]);

    // Users on an active trial don't have access to the Dashboard and get
    // redirected to the plan selection instead
    useEffect(() => {
        if (billingData?.isTrial && !billingData?.errors) {
            return history.push('/plans');
        }
    }, [billingData?.isTrial, billingData?.errors, history]);

    /**
     * Close all modals and reload the billing data
     * @param {{reload?: boolean, delay?: number}} options
     * @returns {Promise<void>}
     */
    const handleCloseModalsAndReload = async (options = {}) => {
        const reload = options?.reload || false;
        const delay = options?.delay || 0;

        setCancelModalIsOpen(false);
        setUpdateCardModalIsOpen(false);
        setBillingModeModalIsOpen(false);
        setInvoiceDetailsModalIsOpen(false);
        setBillingContactModalIsOpen(false);

        if (reload && reload === true) {
            showNav(false);

            setLoading(true);
            setShowCardUpdateModal(false);

            // Wait for a bit before fetching the updated subscription data.
            // This should give Stripe and Daisy enough time to process webhooks
            // and receive the updated subscription state after a customer was in
            // dunning.
            await sleep(delay);

            const updatedBillingData = await getBillingData({updateAdmin: delay > 0 ? true : false});

            setBillingData(updatedBillingData);
            setLoading(false);
        } else {
            const cachedBillingData = await getCachedBillingData();

            await sleep(delay);

            setBillingData(cachedBillingData);
            setInvoiceDetails(cachedBillingData?.invoiceDetails);
        }

        showNav(true);
        return;
    };

    const handleNestedLoadingState = (show) => {
        setLoading(show);
        showNav(!show);
    };

    const contactSupport = (event) => {
        event.preventDefault();
        return window.location.href = 'mailto:support@ghost.org';
    };

    return (
        <main className="main">

            {loading ?
                (
                    <div className="gh-loading-content">
                        <div className="gh-loading-spinner"></div>
                    </div>
                ) :
                (showError) ?
                    (
                        <p style={{textAlign: 'center', gridColumn: '1 / 3'}}>{errorMessage}</p>
                    ) :

                    <div className="accountgrid" data-test-id="dashboard">

                        <PlanDetails
                            currentSiteLimits={billingData?.currentSiteLimits}
                            currentPrice={billingData?.currentPrice}
                            subscriptionMeta={billingData?.subscription?.meta}
                            isSubdirectory={billingData?.currentSite?.isSubdirectory ?? false}
                            pendingSubscription={billingData?.pendingSubscription}
                            isPending={billingData?.isPending}
                            checkoutRoute={billingData?.checkoutRoute}
                            isGrace={billingData?.isGrace}
                            discount={billingData?.discount}
                            customerBalance={customerBalance}
                            currentPeriodEnd={billingData?.subscription?.current_period_end}
                            willCancelAtPeriodEnd={billingData?.willCancelAtPeriodEnd}
                            closeAndReload={handleCloseModalsAndReload}
                            subscriptionId={billingData?.subscription?.id}
                            customerId={billingData?.subscription?.customer_id || billingData?.user?.stripe_token}
                        />

                        <div className="box-carddetails box-wrap">
                            <div className="box-label">Payment information</div>
                            <div className="box">

                                <UpdateCardModal
                                    user={billingData?.user}
                                    customerId={billingData?.subscription?.customer_id || billingData?.user?.stripe_token}
                                    isGrace={billingData?.isGrace}
                                    showCardUpdateModal={showCardUpdateModal}
                                    handleCloseModal={handleCloseModalsAndReload}
                                    handleNestedLoadingState={handleNestedLoadingState}
                                    setModalIsOpen={setUpdateCardModalIsOpen}
                                    modalIsOpen={updateCardModalIsOpen}
                                />

                                {canHaveAutoBilling &&
                                    <BillingModeModal
                                        user={billingData?.user}
                                        subscription={billingData?.subscription}
                                        autoPriceId={billingData?.autoPriceId}
                                        handleCloseModal={handleCloseModalsAndReload}
                                        exceededLimits={billingData?.exceededLimits}
                                        autoBillingEnabled={autoBillingEnabled}
                                        setAutoBillingEnabled={setAutoBillingEnabled}
                                        setModalIsOpen={setBillingModeModalIsOpen}
                                        modalIsOpen={billingModeModalIsOpen}
                                        willCancelAtPeriodEnd={billingData?.willCancelAtPeriodEnd}
                                        isGrace={billingData?.isGrace}
                                    />
                                }

                                <InvoiceDetailsModal
                                    handleCloseModal={handleCloseModalsAndReload}
                                    invoiceDetails={invoiceDetails}
                                    customerId={billingData?.subscription?.customer_id || billingData?.user?.stripe_token}
                                    setModalIsOpen={setInvoiceDetailsModalIsOpen}
                                    modalIsOpen={invoiceDetailsModalIsOpen}
                                />

                                <BillingContactModal
                                    user={billingData?.user}
                                    setModalIsOpen={setBillingContactModalIsOpen}
                                    modalIsOpen={billingContactModalIsOpen}
                                    handleCloseModal={handleCloseModalsAndReload}
                                />

                            </div>
                        </div>

                        {billingData?.invoices &&
                            <Invoices invoices={billingData?.invoices} />
                        }

                        <footer className="footer">

                            {!billingData?.willCancelAtPeriodEnd && (billingData?.isActive || billingData?.isGrace) &&
                                <CancelModal
                                    subscription={billingData?.subscription}
                                    setLoadingState={handleNestedLoadingState}
                                    onCloseModal={handleCloseModalsAndReload}
                                    setModalIsOpen={setCancelModalIsOpen}
                                    modalIsOpen={cancelModalIsOpen}
                                    billingData={billingData}
                                />
                            }

                            <a href="https://ghost.org/help/manage-your-subscription/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">Billing Help</a>
                        </footer>
                    </div>
            }
        </main>
    );
}

Dashboard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    updateTitle: PropTypes.func.isRequired,
    showNav: PropTypes.func
};

export default withRouter(Dashboard);
