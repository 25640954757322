import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import formatPeriod from '../../../utils/format-period';

/**
 * @typedef {object} PendingSubscription
 * @property {string} product
 * @property {'month' | 'year'} period
 */

/**
 * @typedef {object} PendingWarningProps
 * @property {PendingSubscription} pendingSubscription
 * @property {string} checkoutRoute
 */

/**
 * @param {PendingWarningProps} props
 * @returns {JSX.Element}
 */
export default function PendingWarning({
    pendingSubscription,
    checkoutRoute
}) {
    if (!pendingSubscription || Object.keys(pendingSubscription).length === 0 || !checkoutRoute) {
        return null;
    }

    const formattedProduct = `${pendingSubscription.product[0].toUpperCase()}${pendingSubscription.product.substring(1).toLowerCase()}`;

    return (
        <p
            className="red"
            data-testid="pending-warning"
        >
            Your last plan change to <strong>{formattedProduct} ({formatPeriod(pendingSubscription.period)}) isn't active</strong> yet. Click <Link to={checkoutRoute}><strong>here</strong></Link> to complete the payment now.
        </p>
    );
}

PendingWarning.propTypes = {
    pendingSubscription: PropTypes.shape({
        product: PropTypes.string,
        period: PropTypes.string
    }).isRequired,
    checkoutRoute: PropTypes.string
};
